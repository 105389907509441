/* You can add global styles to this file, and also import other style files */
@import "bootstrap-custom";

// allow absolute positioning inside the search field
.tab-form-container {
  position: relative;
}

.tab-form {
  position: absolute;
  top: 0;
  right: 0;
}

.scrollable {
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
}

.chipster-highlight {
  padding: 12px;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
  border-radius: 4px;
  margin-bottom: 12px;
}

.thin-list-group-item {
  /*height: 2em;*/
  padding-top: 4px;
  padding-bottom: 4px;
}

.lighter {
  color: grey;
}

.text-sm {
  font-size: small;
}

.text-medium {
  font-size: medium;
}

.text-smaller {
  font-size: smaller;
}

.cursor-pointer {
  cursor: pointer;
}

// specify the button to make this more specific than other button styles
button.dropdown-menu-button {
  border: 0;
  padding: 0 6px 0 6px; // otherwise the this will be higher than the header
  line-height: 1;
}

// hack to get rid of caret in certain dropdown buttons
button.no-caret-dropdown::after {
  display: none;
}

#selectedGeneList {
  margin-top: 10px;
}

.list-group-sm button {
  padding: 0.25rem 1.25rem;
}

.visualization-panel {
  position: relative; /* required for pdf vizualization */
  padding: 15px;
  background-color: white;
  border: 1px solid #e1e1e8;
  margin-top: 1px;
  // border-top: 0;
  /*
  Keep the scroll position when changing visualizations

  During initialization, visualizations may show only one status text line if anything. This would make the page
  shorter and forces the browser to scroll it upwards. This min-height makes sure that the page is always long enough
  to keep its scroll position. Value of 100vh should be always enough, because if the user would have scrolled more than
  that, the tabs wouldn't be visible anymore and it wouldn't be possible to change the visualization.
   */
  min-height: 100vh;
}

button.btn {
  border-radius: 8px;
}

button.btn-no-border {
  border: 0;
}

.h1-xs {
  font-size: 1.2rem;
}

.h2-xs {
  font-size: 1.1rem;
}

.h3-xs {
  font-size: 1rem;
}

.h4-xs {
  font-size: 0.9rem;
  font-weight: 500;
}

.h5-xs {
  font-size: 0.8rem;
  font-weight: 500;
}

.h6-xs {
  font-size: 0.7rem;
  font-weight: 500;
}

.panel-title {
  font-size: 1rem;
  color: grey;
}

.button-wide {
  width: 9em;
}

.w-4 {
  width: 4em;
}

.w-5 {
  width: 5em;
}

.w-6 {
  width: 6em;
}

.w-7 {
  width: 6em;
}

.w-8 {
  width: 8em;
}

.w-9 {
  width: 9em;
}

#close-button {
  float: right;
  margin-right: 10px;
  font-size: 15px;
}

// Global nav bar style for all the nav items OLD NG-BOOTSTRAP TABS
.nav-item a.nav-link.active {
  border-radius: 0px;
  border-width: 0px 0px 3px 0px;
  border-bottom-style: solid;
  border-bottom-color: $primary;
  color: #000;
  background-color: transparent;
  border-top-width: white;
}
.nav-tabs .navbar-light .navbar-nav a.nav-link.disabled {
  color: rgba(0, 0, 0, 1);
  border-width: 0px 0px 0px 0px;
}

// Global nav bar style for all the nav items NEW NG-BOOTSTRAP NAVS
.nav-tabs a.nav-link.active {
  border-radius: 0px;
  border-width: 0px 0px 3px 0px;
  border-bottom-style: solid;
  border-bottom-color: $primary;
  color: #000;
  background-color: transparent;
  border-top-width: white;
}
.nav-item .navbar-light .navbar-nav a.nav-link.disabled {
  color: rgba(0, 0, 0, 1);
  border-width: 0px 0px 0px 0px;
}

// bootstrap hover stripes and active doesn't work perfecty
// thus some manual styling needed for the jobs table
tr.hoverable-row:hover {
  background-color: $light;
}

.nav-tabs {
  border-bottom: 0;
}

@mixin bottom-border-primary {
  border-bottom-color: $primary;
}

.no-underline-link-button {
  text-decoration: none;
}

.no-underline-link-button:hover {
  text-decoration: underline;
}
