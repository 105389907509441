/* Layout
------------ */

.d3-context-menu {
	position: absolute;
	min-width: 150px;
	z-index: 1200;
}

.d3-context-menu ul,
.d3-context-menu ul li {
	margin: 0;
	padding: 0;
}

.d3-context-menu ul {
	list-style-type: none;
	cursor: default;
}

.d3-context-menu ul li {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none;   /* Chrome/Safari/Opera */
	-khtml-user-select: none;    /* Konqueror */
	-moz-user-select: none;      /* Firefox */
	-ms-user-select: none;       /* Internet Explorer/Edge */
	user-select: none;
}

/*
	Disabled
*/

.d3-context-menu ul li.is-disabled,
.d3-context-menu ul li.is-disabled:hover {
	cursor: not-allowed;
}

/*
	Divider
*/

.d3-context-menu ul li.is-divider {
	padding: 0;
}

/* Theming
------------ */

.d3-context-menu-theme {
	background-color: #f2f2f2;
	border-radius: 4px;

	font-family: Arial, sans-serif;
	font-size: 14px;
	border: 1px solid #d4d4d4;
}

.d3-context-menu-theme ul {
	margin: 4px 0;
}

.d3-context-menu-theme ul li {
	padding: 4px 16px;
}

.d3-context-menu-theme ul li:hover {
	background-color: #4677f8;
	color: #fefefe;
}

/*
	Header
*/

.d3-context-menu-theme ul li.is-header,
.d3-context-menu-theme ul li.is-header:hover {
	background-color: #f2f2f2;
	color: #444;
	font-weight: bold;
	font-style: italic;
}

/*
	Disabled
*/

.d3-context-menu-theme ul li.is-disabled,
.d3-context-menu-theme ul li.is-disabled:hover {
	background-color: #f2f2f2;
	color: #888;
}

/*
	Divider
*/

.d3-context-menu-theme ul li.is-divider:hover {
	background-color: #f2f2f2;
}

.d3-context-menu-theme ul hr {
	border: 0;
	height: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

/*
	Nested Menu
*/
.d3-context-menu-theme ul li.is-parent:after {
	border-left: 7px solid transparent;
	border-top: 7px solid red;
	content: "";
	height: 0;
	position: absolute;
	right: 8px;
	top: 35%;
	transform: rotate(45deg);
	width: 0;
}

.d3-context-menu-theme ul li.is-parent {
	padding-right: 20px;
	position: relative;
}

.d3-context-menu-theme ul.is-children {
	background-color: #f2f2f2;
	border: 1px solid #d4d4d4;
	color: black;
	display: none;
	left: 100%;
	margin: -5px 0;
	padding: 4px 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.d3-context-menu-theme li.is-parent:hover > ul.is-children {
	display: block;
}
