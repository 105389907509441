// See https://getbootstrap.com/docs/5.2/customize/sass/ about importing bootstrap scss.
// For some reason option B on that page didn't seem to work, maybe it wasnt't including all the
// separate files.

// Now importing functions first so that they can be used to manipulate colors if needed, and them
// importing all the rest, which I guess imports functions again. Seems to work

// Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// Override variables
// shade-color(red, 20%) to darken
// ting-color(red, 20%) to lighten

$primary: #006fe6;
$secondary: #6c757d;
$success: shade-color(#23923d, 10%); // hex is the color we used to have, need to darken a bit for the text to go white
$info: shade-color(#148ea1, 10%);
// $info: #148ea1;
// $warning: magenta;
// $danger: magenta;
// $light: magenta;
// $dark: magenta;

$table-active-color: white;
$table-active-bg: $primary;

// Import all the bootstrap (functions will be imported again)
@import "../node_modules/bootstrap/scss/bootstrap";

// This is herebecause when having directly in the angular.json
// the bootstrap stuff didn't seem to get loaded before ngx-toaster --> errors.
// https://github.com/scttcper/ngx-toastr/issues/318
@import "../node_modules/ngx-toastr/toastr-bs5-alert.scss";
